import React, { useEffect } from "react"
import { Footer, SEO, Layout, Hero } from "@components"
import Options from "@components/pages/support/Options.js"
import { StaticImage } from "gatsby-plugin-image"

const SupportPage = () => {
  useEffect(() => {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = "d8f640b5-f818-4ffd-83ea-f528978496cc"
    ;(function() {
      var d = document
      var s = d.createElement("script")

      s.src = "https://client.crisp.chat/l.js"
      s.async = 1
      d.getElementsByTagName("head")[0].appendChild(s)
    })()
  })
  return (
    <>
      <Layout>
        <SEO
          title="Dynobase Support"
          description="Dynobase Support"
          canonical="https://dynobase.dev/support/"
        />
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Hero title="Dynobase Product & Sales Support" />

          <StaticImage
            src={"../images/support.png"}
            placeholder="none"
            alt="Support illustration"
            style={{
              height: "300px",
              width: "300px",
              transform: "scaleX(-1)",
            }}
          />
          <h2 style={{ fontSize: "2.5em" }}>We're here to help</h2>
          <h3
            style={{
              color: "#999",
              fontWeight: 400,
              fontSize: "1.25em",
              textAlign: "center",
            }}
          >
            Get in touch anytime for help or to give feedback
          </h3>
          {/* <a href="mailto:support@dynobase.dev">support@dynobase.dev</a> */}

          <Options />

          <a href="/dynobase-and-aws-sso/" style={{ marginTop: "25px" }}>
            Problems with SSO-based profiles? Read more.
          </a>
          <a href="/run-dynamodb-locally/#in-dynobase">
            Problems with Local/Offline DynamoDB? Read more.
          </a>

          <Footer />
        </div>
      </Layout>
    </>
  )
}

export default SupportPage
