import React from "react"
import { Container } from "@components"
import { Box, Flex } from "rebass"
import SingleOption from "./SingleOption"

const Content = props => (
  <Container>
    <Box mt={[1, 1, 1, 12]}>
      <Flex flexDirection={["column", "column", "row"]}>
        <SingleOption
          title="Live Chat"
          description="Use the live chat widget below to get started"
        />
        <SingleOption
          title="Email"
          description="Email us here: support@dynobase.dev"
        />
      </Flex>
    </Box>
  </Container>
)

export default Content
