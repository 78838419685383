import React from "react"
import { Box } from "rebass"

const SingleOption = ({ title, description }) => (
  <Box
    width={[1, 0.5]}
    sx={{
      position: "relative",
      display: "inline-flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
      padding: 10,
    }}
  >
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px",
        padding: "20px 30px",
        position: "relative",
        overflow: "hidden",
        minWidth: ['100%', '100%', '65%']
      }}
    >
      <h4
        style={{
          textAlign: "center",
          paddingTop: "12px",
          paddingBottom: '6px',
          color: "black",
          position: "relative",
          fontSize: "1.5em",
          fontWeight: "600",
        }}
      >
        {title}
      </h4>
      <div style={{
          textAlign: "center",
          width: '75%',
          margin: '0 auto'
      }}>
      <p
        style={{
          marginBottom: "10px",
          color: "black",
        }}
      >
        {description}
      </p>
      </div>
    </Box>
  </Box>
)

export default SingleOption
